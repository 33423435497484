.history_wrapper {
  background-color: #fff6f0;
  box-shadow: 4px 4px 27px 0px rgba(0, 0, 0, 0.2117647059);
  border: 1px solid #f47115;
  height: 100%;
  width: 100%;
  max-height: 450px;
  background-size: cover;
  background-repeat: no-repeat;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;

  .history_header {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    border-bottom: 1px solid black;
    padding: 16px 24px;
    font-size: 24px;
    color: #f47115;
  }

  .history_content {
    flex: 1;
    overflow-y: scroll;
    padding: 10px 10px;
    display: flex;
    flex-direction: column;
    gap: 12px;

    .textarea {
      resize: none;
      height: 120px;
      background-color: transparent;
      font-size: 16px;
      font-weight: 700;
    }
  }

  .history_title {
    display: flex;
    align-items: center;

    &__label {
      color: #ff790b;
      font-size: 16px;
      margin-right: 10px;
    }

    &__value {
      color: white;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 20px;
    }
  }
}

// .customTableCell {
//   border: 1px solid white;
//   padding: 1rem;
//   font-size: 14px;
//   color: white;

//   @media screen and (max-width: 768px) {
//     font-size: 12px;
//   }
// }
