@font-face {
  font-family: Poppins;
  font-weight: 400;
  src: url("./assets/font/OpenSauceSans-Regular.ttf") format("woff");
}

@font-face {
  font-family: "Clash Grotesk";
  font-weight: 500;
  src: url("./assets/font/ClashGrotesk-Semibold.woff") format("woff");
}

@font-face {
  font-family: Clash-regular;
  font-weight: 400;
  src: url("./assets/font/ClashGrotesk-Regular.woff") format("woff");
}

@font-face {
  font-family: clash-grotesk;
  src: url("./assets/font/ClashGrotesk-Medium.woff") format("woff");
}

@font-face {
  font-family: clash-grotesk-extralight;
  src: url("./assets/font/ClashGrotesk-Extralight.woff") format("woff");
}

@font-face {
  font-family: clash-grotesk-light;
  src: url("./assets/font/ClashGrotesk-Light.woff") format("woff");
}

/* 400 */
@font-face {
  font-family: Matrix-Sans-Video-Regular;
  src: url("./assets/font/MatrixSansVideo-Regular.woff2") format("woff");
}

@font-face {
  font-family: Matrix-Sans-Video;
  src: url("./assets/font/MatrixSans-Video.ttf") format("woff");
}

@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP&family=Orbitron:wght@400..900&display=swap");

.theme-animals {
  --font-display: Inter;
  --font-body: Inter;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}

body {
  margin: 0;
  cursor: url("./assets/img/icon-cursor.png"), auto;
}

.signika {
  font-family: "Signika Negative", "Signika Negative Variable Font";
}

code {
  font-family: MatrixSansRegular, source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}

::-webkit-scrollbar {
  width: 4px;
  height: 6px;
}

::-webkit-scrollbar-track {
  border-radius: 65654px;
  background: rgba(255, 255, 255, 0.03);
  backdrop-filter: blur(25px);
}

::-webkit-scrollbar-thumb {
  border-radius: 65654px;
  background-color: #f47115;
}
