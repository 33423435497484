.confirmModalWrapper {
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  display: grid;
  place-items: center;
  top: 0;
  left: 0;

  &__hidden {
    display: none;
  }

  .confirmModal {
    background: #171717;
    border-top: none;
    border-bottom: 6px solid #f47115;
    border-radius: 7px;
    max-width: 768px;
    width: 100%;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 29px;
    position: relative;
    margin: 0 20px;
    box-sizing: border-box;

    .modalClose {
      width: 48px;
      height: 52px;
      position: absolute;
      top: 20px;
      right: 30px;
      cursor: pointer;
    }
    .ognlInfo {
      max-width: 590px;
      width: 100%;
      text-align: center;
      font-family: MatrixSansRegular;
      font-size: 20px;
      color: white;
      line-height: 31px;

      .congrats {
        width: 40px;
        position: relative;
        img {
          width: 40px;
          position: absolute;
          bottom: 0;
        }
      }
    }
    .done {
      width: 90px;
      margin-top: 45px;
    }
    .logo {
      max-width: 236px;
      width: 100%;
      margin-top: 30px;
    }

    .history_title {
      display: flex;
      align-items: center;

      &__label {
        color: #ff790b;
        font-size: 16px;
        margin-right: 10px;
      }

      &__value {
        color: white;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 20px;
      }
    }

    .socialInfo {
      font-family: Inter;
      font-size: 21.23px;
      font-weight: 500;
      line-height: 25.7px;
      text-align: center;
      max-width: 634px;
      color: #ff790b;
    }

    .socialIcons {
      display: flex;
      gap: 17px;
      margin-bottom: 48px;
      img {
        width: 39px;
      }
    }

    .confirmButton {
      padding: 20px 0;
      border-radius: 10px;
      border: 1px 0px 0px 0px;
      background: linear-gradient(225deg, #f59f47 14.89%, #fa7714 85.85%);
      font-family: MatrixSansRegular;
      font-size: 16px;
      font-weight: 400;
      line-height: 16px;
      text-align: center;
      color: #ffffff;
      max-width: 377px !important;
      width: 100%;
      cursor: pointer;
      margin: 0 30px 40px;
    }

    .cancel {
      font-family: MatrixSansRegular;
      font-weight: bold;
      cursor: pointer;
      width: 160px;
      height: 50px;
      border-radius: 8px;
      border: none;
      color: #fff;
      background-color: #eb5757;
      font-size: 17px;
      transition: 0.2s;
      margin: 20px 0;

      &:hover {
        background-color: #fc4747;
      }
    }

    @media screen and (max-width: 1080px) {
      width: 90%;
    }

    @media screen and (max-width: 768px) {
      padding: 0;
      .done {
        width: 70px;
        margin-top: 21px;
      }
      .logo {
        width: 136px;
        margin-top: 34px;
      }
      .modalClose {
        height: 32px;
        width: 26px;
      }
      .ognlInfo {
        max-width: 317px;
        font-size: 14px;
        line-height: 21px;

        .congrats {
          width: 32px;
          img {
            width: 32px;
          }
        }
      }
      .socialInfo {
        font-size: 13px;
        font-weight: 500;
        line-height: 15.53px;
        max-width: 308px;
      }

      .socialIcons {
        gap: 12px;
        margin-bottom: 36px;
        img {
          width: 28px;
        }
      }
      .olngTokenContainer {
        font-size: 10px;
        padding: 12px 0;
        max-width: 214px;
      }
      .confirmButton {
        max-width: 251px !important;
        margin-bottom: 60px;
      }
    }
  }

  .confirmButtons {
    display: flex;
  }
}

.hide {
  display: hidden;
}
