.history_wrapper {
  background-color: #fff6f0;
  box-shadow: 4px 4px 27px 0px rgba(0, 0, 0, 0.2117647059);
  border: 1px solid #f47115;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 16px;
  margin-bottom: 30px;

  .history_header {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    border-bottom: 1px solid black;
    padding: 24px;
    font-size: 24px;
    color: black;

    span {
      color: #ff790b;
      font-weight: bold;
    }
  }

  .history_content {
    margin-top: 20px;
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .history_title {
    display: flex;
    align-items: center;

    &__label {
      color: #ff790b;
      font-size: 16px;
      margin-right: 10px;
    }

    &__value {
      color: white;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 20px;
    }
  }
}

// .customTableCell {
//   border: 1px solid white;
//   padding: 1rem;
//   font-size: 14px;
//   color: white;

//   @media screen and (max-width: 768px) {
//     font-size: 12px;
//   }
// }
