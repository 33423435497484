.container {
  width: 100%;
  min-height: calc(100vh - 326px);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #232323;
  flex-direction: column;
  padding-top: 100px;
  font-family: Clash-regular;

  .connect_wrapper {
    display: flex;
    align-items: center;

    .connect_label {
      margin-left: 20px;
    }
  }

  .connectWallet {
    border-radius: 10px;
    border: 1px solid var(--gradient-light, rgba(255, 255, 255, 0.47));
    background-image: url("../../assets/img/ordict-button.png");
    background-color: transparent;
    border: none;
    background-repeat: no-repeat;
    background-size: contain;
    width: 158px;
    height: 50px;
    color: black;
    padding: 11px 17px;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    font-family: MatrixSansRegular;
    font-size: 18px;
    font-weight: 400;
    cursor: pointer;
    margin: 0;

    &:disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }

  .metamask_section {
    display: flex;
    align-items: center;
    gap: 20px;
    border: 1px solid #ff790b;
    align-self: flex-start;
    padding: 10px 20px;
    border-radius: 6px;
    transition: 0.2s;
    margin-bottom: 20px;

    &:hover {
      cursor: pointer;
      background-color: #ff790b50;
    }
    span {
      font-size: 20px;
      font-weight: 600;
      color: #ff790b;
    }
  }

  .allocation {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 40px;

    .allocation_total {
      display: flex;
      align-items: center;
      padding-bottom: 30px;
      border-bottom: 1px dashed white;
      &__label {
        color: #ff790b;
        font-size: 28px;
        margin-right: 10px;
      }

      &__value {
        color: black;
        font-size: 20px;
      }
    }

    .allocation_status {
      display: flex;
      align-items: center;
      border-bottom: 1px dashed white;
      &__label {
        color: #ff790b;
        font-size: 20px;
        margin-right: 10px;
      }

      &__value {
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
}

.allocation_header {
  background-color: #fff6f0;
  box-shadow: 4px 4px 27px 0px rgba(0, 0, 0, 0.2117647059);
  border: 1px solid #f47115;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 28px;
  margin-bottom: 40px;

  .confirmed_allocation {
    display: flex;
    font-size: 20px;
    color: #f47115;
  }
}

.activeCircle {
  background-color: #f47115;
  color: white;
}
.activeName {
  color: #f47115;
}
.activeLine {
  background-color: #f47115;
}

.disconnect {
  font-family: MatrixSansRegular;
  font-weight: bold;
  cursor: pointer;
  width: 200px;
  height: 50px;
  border-radius: 8px;
  border: none;
  color: black;
  background-color: #eb5757;
  font-size: 17px;
  opacity: 0.8;
  transition: 0.2s;
  margin: 20px 0;

  &:hover {
    background-color: #fc4747;
  }
}

.connected_section {
  display: flex;
  flex-direction: column;
}

.connected_wallet_wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  .connected_address {
    display: flex;
    margin-right: 20px;

    &__label {
      color: #f47115;
      margin-right: 10px;
    }

    &__address {
    }
  }
}

.successContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;

  .ognlInfo {
    max-width: 590px;
    width: 100%;
    text-align: center;
    font-family: MatrixSansRegular;
    font-size: 30px;
    line-height: 31px;
    margin-bottom: 26px;
  }

  .olngTokenContainer {
    border: 2px solid #f47115;
    padding: 15px 0;
    max-width: 400px;
    width: 100%;
    text-align: center;
    font-size: 12px;
    span {
      color: #f47115;
    }
  }
}
