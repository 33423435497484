.container {
  width: 100%;
  min-height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  flex-direction: column;
  padding-top: 100px;

  .not_found {
    color: #f47115;
    font-size: 32px;
    margin-bottom: 30px;
  }

  .go_home {
    font-family: MatrixSansRegular;
    font-weight: bold;
    cursor: pointer;
    width: 200px;
    height: 50px;
    border-radius: 8px;
    border: none;
    color: black;
    background-color: #eb5757;
    font-size: 17px;
    opacity: 0.8;
    transition: 0.2s;
    margin: 20px 0;

    &:hover {
      background-color: #fc4747;
    }
  }

  @media screen and (max-width: 600px) {
    .not_found {
      font-size: 20px;
    }

    .go_home {
      width: 160px;
      height: 42px;
      border-radius: 8px;
      font-size: 14px;
      margin: 12px 0;
    }
  }
}
