.footer {
  bottom: 0;
  width: 100%;
  position: relative;
  font-family: Clash-regular;
  background: linear-gradient(
      to top,
      rgba(255, 255, 255, 0) 20%,
      rgba(255, 255, 255, 1)
    ),
    rgba(250, 119, 20, 0.5);
}

.footer-bg {
  position: absolute;
  width: 100%;
  height: 100%;
}

.footer-body {
  max-width: 1320px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

@media screen and (max-width: 1400px) {
  .footer-body {
    max-width: 1140px;
  }
}
@media screen and (max-width: 1200px) {
  .footer-body {
    max-width: 960px;
  }
}
@media screen and (max-width: 992px) {
  .footer-body {
    max-width: 720px;
  }
}

hr {
  border: 1px solid #fa7714;
}

.footer-menu {
  z-index: 900;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  font-family: Clash-regular;
  .footer-menu-items {
    max-width: 650px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 26px;
    margin-right: 40px;
    .footer-menu-link {
      a {
        text-decoration: none;
        color: #000;
        cursor: url("../../assets/img/icon-pointer.png"), auto;
      }
    }
  }
  .footer-contact {
    display: flex;
    gap: 37px;
    .contact {
      display: flex;
      gap: 11px;
    }
    .social-media-links {
      img {
        cursor: url("../../assets/img/icon-pointer.png"), auto;
      }
    }
  }
}

.social-media-links {
  display: flex;
  justify-content: center;
  gap: 0.2rem;
}

.logo-wrap {
  z-index: 900;
  margin-bottom: 25px;
  margin-top: 30px;
  img {
    cursor: url("../../assets/img/icon-pointer.png"), auto;
  }
}

.footer-bottom {
  font-size: 18px;
  font-family: Clash-regular;
  margin-bottom: 25px;
}

@media (max-width: 1250px) {
  .footer-bottom {
    font-size: 18px;
    gap: 20px;
  }
}

@media (max-width: 1000px) {
  .footer-body {
    padding: 1.5rem 2rem;
    align-items: center;
    font-size: 20px;
  }
  .footer-menu {
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media (max-width: 1000px) {
  .logo-wrap {
    margin-bottom: 0;
    margin-top: 0;
  }
  .footer-menu {
    flex-direction: column;
    justify-content: center;
    .footer-menu-items {
      gap: 25px;
      margin-right: 0;
      margin-bottom: 35px;
      flex-direction: column;
      align-items: center;
    }
    .footer-contact {
      flex-direction: column;
      gap: 23px;
      .contact {
        display: flex;
        gap: 11px;
      }
    }
  }
  hr {
    width: 90vw;
  }
  .footer-bottom {
    font-size: 14px;
    font-family: Clash-regular;
    margin-bottom: 0;
  }
}
