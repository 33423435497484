.loader_container {
  z-index: 999;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000000a0;
  display: flex;
  justify-content: center;
  align-items: center;

  &.hidden {
    display: none;
  }

  .loader {
    --s: 20px;

    --_d: calc(0.353 * var(--s));
    width: calc(var(--s) + var(--_d));
    aspect-ratio: 1;
    display: grid;
  }

  .loader:before,
  .loader:after {
    content: "";
    grid-area: 1/1;
    clip-path: polygon(
      var(--_d) 0,
      100% 0,
      100% calc(100% - var(--_d)),
      calc(100% - var(--_d)) 100%,
      0 100%,
      0 var(--_d)
    );
    background: conic-gradient(
      from -90deg at calc(100% - var(--_d)) var(--_d),
      #f47115 135deg,
      #f7a548 0 270deg,
      #f69134 0
    );
    animation: l6 2s infinite;
  }
  .loader:after {
    animation-delay: -1s;
  }
  @keyframes l6 {
    0% {
      transform: translate(0, 0);
    }
    25% {
      transform: translate(30px, 0);
    }
    50% {
      transform: translate(30px, 30px);
    }
    75% {
      transform: translate(0, 30px);
    }
    100% {
      transform: translate(0, 0);
    }
  }
}
