.select_walelt {
  color: #fa7714;
  display: flex;
  margin-top: 20px;
  justify-content: center;

  &__content {
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: "center";
  }

  &__title {
    font-size: 20px;
    text-align: center;
  }

  &__icons {
    display: flex;
    gap: 20px;

    img {
      width: 100px;
      height: 100px;
      cursor: pointer;
    }
  }
}
