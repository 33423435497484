.container {
  width: 100%;
  min-height: calc(100vh - 326px);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #232323;
  flex-direction: column;
  padding-top: 100px;
  font-family: Clash-regular;

  .content {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
  }

  .connect_wrapper {
    display: flex;
    align-items: center;

    .connect_label {
      margin-left: 20px;
    }
  }
  .formWrapper {
    margin-bottom: 40px;
    width: 660px;
  }
  .inputContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 22px;
    margin-top: 30px;

    .inputWrapper {
      position: relative;
      display: flex;
      gap: 20px;

      @media screen and (min-width: 768px) {
        width: 100%;
      }

      p {
        position: absolute;
        font-family: MatrixSansRegular;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        color: #e84142;
        margin: 0;
        top: 110%;
      }
    }

    .statusMessage {
      height: 20px;
      font-size: 11px;
      font-weight: 300;
      color: #59ff59;

      &.error {
        color: red;
      }

      &.hidden {
        visibility: hidden;
      }
    }
  }

  .allocation {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 40px;

    .allocation_total {
      display: flex;
      align-items: center;
      padding-bottom: 30px;
      border-bottom: 1px dashed white;
      &__label {
        color: #ff790b;
        font-size: 28px;
        margin-right: 10px;
      }

      &__value {
        color: white;
        font-size: 20px;
      }
    }

    .allocation_status {
      display: flex;
      align-items: center;
      border-bottom: 1px dashed white;
      &__label {
        color: #ff790b;
        font-size: 20px;
        margin-right: 10px;
      }

      &__value {
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
}

.connectWallet {
  border-radius: 10px;
  border: 1px solid var(--gradient-light, rgba(255, 255, 255, 0.47));
  background-image: url("../../assets/img/ordict-button.png");
  background-color: transparent;
  border: none;
  background-repeat: no-repeat;
  background-size: contain;
  width: 158px;
  height: 50px;
  color: black;
  padding: 11px 17px;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  font-family: MatrixSansRegular;
  font-size: 18px;
  font-weight: 400;
  cursor: pointer;
  margin: 0;

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
}

.allocation_header {
  background-image: url("../../assets/img/ordict-bg.png");
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 28px;
  margin-bottom: 40px;
  .confirmed_allocation {
    display: flex;
    font-size: 20px;
    color: #f47115;
  }
}

.confirmModalWrapper {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  display: grid;
  place-items: center;
  top: 0;
  left: 0;
  .confirmModal {
    background: #171717;
    border-top: none;
    border-bottom: 6px solid #f47115;
    border-radius: 7px;
    max-width: 768px;
    width: 100%;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 29px;
    position: relative;
    margin: 0 20px;
    box-sizing: border-box;

    .modalClose {
      width: 48px;
      height: 52px;
      position: absolute;
      top: 20px;
      right: 30px;
      cursor: pointer;
    }
    .done {
      width: 90px;
      margin-top: 45px;
    }
    .logo {
      max-width: 236px;
      width: 100%;
      margin-top: 45px;
    }

    .olngTokenContainer {
      border: 2px solid #f47115;
      padding: 15px 0;
      max-width: 400px;
      width: 100%;
      text-align: center;
      font-size: 14px;
      display: flex;
      flex-direction: column;
      span {
        color: #f47115;
      }
    }

    .ognlInfo {
      max-width: 590px;
      width: 100%;
      text-align: center;
      font-family: MatrixSansRegular;
      font-size: 20px;
      // line-height: 36px;
      line-height: 31px;

      .congrats {
        width: 40px;
        position: relative;
        img {
          width: 40px;
          position: absolute;
          bottom: 0;
        }
      }
    }

    .history_title {
      display: flex;
      align-items: center;

      &__label {
        color: #ff790b;
        font-size: 16px;
        margin-right: 10px;
      }

      &__value {
        color: white;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 20px;
      }
    }

    .socialInfo {
      font-family: Inter;
      font-size: 21.23px;
      font-weight: 500;
      line-height: 25.7px;
      text-align: center;
      max-width: 634px;
      color: #ff790b;
    }

    .socialIcons {
      display: flex;
      gap: 17px;
      margin-bottom: 48px;
      img {
        width: 39px;
      }
    }

    .confirmButton {
      top: 540px;
      left: 423px;
      padding: 20px 0;
      border-radius: 10px;
      border: 1px 0px 0px 0px;
      background: linear-gradient(225deg, #f59f47 14.89%, #fa7714 85.85%);
      font-family: MatrixSansRegular;
      font-size: 16px;
      font-weight: 400;
      line-height: 16px;
      text-align: center;
      color: #ffffff;
      max-width: 377px !important;
      width: 100%;
      cursor: pointer;
      margin: 0 30px 65px;
    }

    .cancel {
      font-family: MatrixSansRegular;
      font-weight: bold;
      cursor: pointer;
      width: 160px;
      height: 50px;
      border-radius: 8px;
      border: none;
      color: #fff;
      background-color: #eb5757;
      font-size: 17px;
      transition: 0.2s;
      margin: 20px 0;

      &:hover {
        background-color: #fc4747;
      }
    }

    @media screen and (max-width: 1080px) {
      width: 90%;
    }

    @media screen and (max-width: 768px) {
      padding: 0;
      .done {
        width: 70px;
        margin-top: 21px;
      }
      .logo {
        width: 136px;
        margin-top: 34px;
      }
      .modalClose {
        height: 32px;
        width: 26px;
      }
      .ognlInfo {
        max-width: 317px;
        font-size: 14px;
        line-height: 21px;

        .congrats {
          width: 32px;
          img {
            width: 32px;
          }
        }
      }
      .socialInfo {
        font-size: 13px;
        font-weight: 500;
        line-height: 15.53px;
        max-width: 308px;
      }

      .socialIcons {
        gap: 12px;
        margin-bottom: 36px;
        img {
          width: 28px;
        }
      }
      .olngTokenContainer {
        font-size: 10px;
        padding: 12px 0;
        max-width: 214px;
      }
      .confirmButton {
        max-width: 251px !important;
        margin-bottom: 60px;
      }
    }
  }

  .confirmButtons {
    display: flex;
  }
}

.activeCircle {
  background-color: #f47115;
  color: white;
}
.activeName {
  color: #f47115;
}
.activeLine {
  background-color: #f47115;
}

.disconnect {
  font-family: MatrixSansRegular;
  font-weight: bold;
  cursor: pointer;
  width: 200px;
  height: 50px;
  border-radius: 8px;
  border: none;
  color: black;
  background-color: #eb5757;
  font-size: 17px;
  opacity: 0.8;
  transition: 0.2s;
  margin: 20px 0;

  &:hover {
    background-color: #fc4747;
  }
}

.connected_wallet_wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  .connected_address {
    display: flex;
    margin-right: 20px;

    &__label {
      color: #f47115;
      margin-right: 10px;
    }
  }
}

.successContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;

  .ognlInfo {
    max-width: 590px;
    width: 100%;
    text-align: center;
    font-family: MatrixSansRegular;
    font-size: 30px;
    line-height: 31px;
    margin-bottom: 26px;
  }

  .olngTokenContainer {
    border: 2px solid #f47115;
    padding: 15px 0;
    max-width: 400px;
    width: 100%;
    text-align: center;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    span {
      color: #f47115;
    }
  }
}
