.historyTable {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.historyTableHeaderRow {
  border: none;
  font-size: 16px;

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
}

.historyTableHeaderCell {
  border: none;
  padding: 1rem;
  font-weight: bold;
  font-size: 18px;
  color: #f47115;
}

.historyTableRow {
  border: none;
}

.historyTableTotalRow {
  border: none;
  font-weight: bold;
  color: black;
}

.historyTableCell {
  border: none;
  padding: 1rem;
  font-size: 14px;
  color: black;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
}

.historyTableTotalCell {
  font-weight: bold;
  color: black;
}
