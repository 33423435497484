.containerWrap {
  position: relative;

  .bgImage {
    background: url("../../assets/img/linedBackground.png") 0% 0% / cover;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -1;

    @media screen and (max-width: 768px) {
      object-fit: cover;
    }
  }

  .bigContainer {
    max-width: 1740px;
    margin: 0 auto;
    padding: 0 20px;
  }
}
