//  Variables
//  -----------------------------
//
//  Table of Contents:
//
//   1. Font family
//   2. Primary Colors
//   3. Content Colors
//   4. Secondary Colors
//   5. Background Colors
//   6. Status Colors
//   7. Action Colors
//   8. Text Colors
//   9. Border Colors
//   10. Font Size
//   11. Font Weight
//   12. Space System
//   13. Border radius
//   14. Responsive Size

// 1. Font family
// ---------
$font-family: "Poppins", sans-serif;

// 2. Primary Colors
// ---------
$white: #ffffff;
$black: #000000;
$primary-blue: #01396c;
$primary-green: #02c9a5;
$background-dark: #00100d;
$background-orange: #fdb456;

// 3. Content Colors
$content-gray: #c2cdcd;
$content-action: #363a41;
$content-accent-green: #67b5b8;
$content-light-gray: #eff7f6;

// 4. Secondary Colors
// ---------

// 5. Background Colors
$background-white: #fcfcfc;
$background-black: #00100d;
$background-gray: #00100dcc;
$background-modal: rgba(0, 0, 0, 0.5);
$background-dark-gray: #24262a;
$background-light-gray: #363a41;

// 6. Status Colors
// ---------
$status-error: #e42a00;
$status-warning: #ffab00;
$status-success: #02ad8e;
$status-info: #4ba8fd;

// 7. Action Colors
// ---------
$action-active: #028389;
$action-active-2: #359ca1;
$action-pressed: #015445;
$action-enabled: #c1d2d0;
$action-disabled: #404c4a;
$button-hover: #026367;
$button-active: #01474a;

// 8. Text Colors
// ---------
$text-white: #fcfcfc;
$text-white-2: #fafefd;
$text-gray: #d4dcdb;
$text-light-gray: #eff7f6;
$text-gray-secondary: #c6c6c9;
$text-black: #010b13;
$text-black-2: #00241e;
$text-dark-blue: #173476;
$text-dark-gray: #494f58;
$text-gray-3: #717782;
$text-gray-2: #7a8482;
$text-primary-green: #00dc89;
$text-dark-green: #02ab6b;
$text-content-green: #028389;
$text-content-gray: #495351;
$text-content-very-light: #c6c6c9;

// 9. Border Colors
// ---------
$border-color-subtle: #e9eded;
$border-color-accordion: #c9c9c9;
$border-color-disabled: #566563;
$border-color-error: #ff522b;

// 10. Font Size
// ---------
$font-size-4: 0.25rem;
$font-size-8: 0.5rem;
$font-size-10: 0.625rem;
$font-size-12: 0.75rem;
$font-size-14: 0.875rem;
$font-size-16: 1rem;
$font-size-18: 1.125rem;
$font-size-20: 1.25rem;
$font-size-24: 1.5rem;
$font-size-26: 1.625rem;
$font-size-28: 1.75rem;
$font-size-32: 2rem;
$font-size-36: 2.25rem;
$font-size-40: 2.5rem;
$font-size-42: 2.62rem;
$font-size-48: 3rem;
$font-size-64: 4rem;

// 11. Font Weight
// ---------
$font-weight-100: 100;
$font-weight-200: 200;
$font-weight-300: 300;
$font-weight-400: 400;
$font-weight-500: 500;
$font-weight-600: 600;
$font-weight-700: 700;
$font-weight-800: 800;
$font-weight-900: 900;

// 12. Space System
// ---------
$space-1: 0.0625rem; //1px;
$space-2: 0.125rem; //2px;
$space-3: 0.1875rem;
$space-4: 0.25rem; //4px;
$space-5: 0.3125rem;
$space-6: 0.375rem;
$space-7: 0.4375rem;
$space-8: 0.5rem;
$space-10: 0.625rem;
$space-11: 0.6875rem;
$space-12: 0.75rem;
$space-14: 0.875rem;
$space-15: 0.938rem;
$space-16: 1rem;
$space-17: 1.0625rem;
$space-18: 1.125rem;
$space-19: 1.1875rem;
$space-20: 1.25rem;
$space-21: 1.3125rem;
$space-23: 1.438rem;
$space-24: 1.5rem;
$space-25: 1.563rem;
$space-26: 1.625rem;
$space-27: 1.688rem;
$space-28: 1.75rem;
$space-30: 1.875rem;
$space-32: 2rem;
$space-33: 2.0625rem;
$space-34: 2.125rem;
$space-36: 2.25rem;
$space-38: 2.375rem;
$space-39: 2.438rem;
$space-40: 2.5rem;
$space-42: 2.625rem;
$space-44: 2.75rem;
$space-46: 2.875rem;
$space-48: 3rem;
$space-50: 3.125rem;
$space-52: 3.25rem;
$space-53: 3.3125rem;
$space-56: 3.5rem;
$space-60: 3.75rem;
$space-64: 4rem;
$space-68: 4.25rem;
$space-72: 4.5rem;
$space-73: 4.5625rem;
$space-75: 4.688rem;
$space-76: 4.75rem;
$space-78: 4.875rem;
$space-80: 5rem;
$space-86: 5.375rem;
$space-88: 5.5rem;
$space-90: 5.625rem;
$space-92: 5.75rem;
$space-93: 5.813rem;
$space-94: 5.875rem;
$space-96: 6rem;
$space-98: 6.125rem;
$space-100: 6.25rem;
$space-104: 6.5rem;
$space-106: 6.625rem;
$space-108: 6.75rem;
$space-112: 7rem;
$space-116: 7.25rem;
$space-118: 7.375rem;
$space-119: 7.438rem;
$space-120: 7.5rem;
$space-121: 7.5625rem;
$space-128: 8rem;
$space-129: 8.063rem;
$space-130: 8.125rem;
$space-132: 8.25rem;
$space-135: 8.438rem;
$space-136: 8.5rem;
$space-138: 8.625rem;
$space-144: 9rem;
$space-146: 9.125rem;
$space-150: 9.375rem;
$space-154: 9.625rem;
$space-160: 10rem;
$space-164: 10.25rem;
$space-176: 11rem;
$space-182: 11.375rem;
$space-186: 11.625rem;
$space-188: 11.75rem;
$space-200: 12.5rem;
$space-216: 13.5rem;
$space-220: 13.75rem;
$space-230: 14.375rem;
$space-243: 15.188rem;
$space-244: 15.25rem;
$space-245: 15.313rem;
$space-294: 18.375rem;
$space-300: 18.75rem;
$space-342: 21.375rem;
$space-356: 22.25rem;
$space-384: 24rem;
$space-536: 33.5rem;
$space-581: 36.3125rem;
$space-1120: 70rem;

// 12. Space System
// ---------
$space-1: 0.0625rem; //1px;
$space-2: 0.125rem; //2px;
$space-3: 0.1875rem;
$space-4: 0.25rem; //4px;
$space-5: 0.3125rem;
$space-6: 0.375rem;
$space-7: 0.4375rem;
$space-8: 0.5rem;
$space-10: 0.625rem;
$space-11: 0.6875rem;
$space-12: 0.75rem;
$space-14: 0.875rem;
$space-15: 0.938rem;
$space-16: 1rem;
$space-18: 1.125rem;
$space-19: 1.1875rem;
$space-20: 1.25rem;
$space-21: 1.3125rem;
$space-23: 1.438rem;
$space-24: 1.5rem;
$space-25: 1.563rem;
$space-26: 1.625rem;
$space-27: 1.688rem;
$space-28: 1.75rem;
$space-30: 1.875rem;
$space-32: 2rem;
$space-34: 2.125rem;
$space-36: 2.25rem;
$space-38: 2.375rem;
$space-39: 2.438rem;
$space-40: 2.5rem;
$space-42: 2.625rem;
$space-44: 2.75rem;
$space-46: 2.875rem;
$space-48: 3rem;
$space-50: 3.125rem;
$space-52: 3.25rem;
$space-53: 3.3125rem;
$space-56: 3.5rem;
$space-60: 3.75rem;
$space-64: 4rem;
$space-68: 4.25rem;
$space-72: 4.5rem;
$space-73: 4.5625rem;
$space-75: 4.688rem;
$space-76: 4.75rem;
$space-78: 4.875rem;
$space-80: 5rem;
$space-88: 5.5rem;
$space-90: 5.625rem;
$space-92: 5.75rem;
$space-93: 5.813rem;
$space-94: 5.875rem;
$space-96: 6rem;
$space-98: 6.125rem;
$space-100: 6.25rem;
$space-104: 6.5rem;
$space-108: 6.75rem;
$space-112: 7rem;
$space-116: 7.25rem;
$space-118: 7.375rem;
$space-119: 7.438rem;
$space-120: 7.5rem;
$space-121: 7.5625rem;
$space-128: 8rem;
$space-129: 8.063rem;
$space-130: 8.125rem;
$space-132: 8.25rem;
$space-135: 8.438rem;
$space-136: 8.5rem;
$space-144: 9rem;
$space-146: 9.125rem;
$space-150: 9.375rem;
$space-154: 9.625rem;
$space-160: 10rem;
$space-164: 10.25rem;
$space-176: 11rem;
$space-182: 11.375rem;
$space-186: 11.625rem;
$space-188: 11.75rem;
$space-200: 12.5rem;
$space-216: 13.5rem;
$space-220: 13.75rem;
$space-230: 14.375rem;
$space-243: 15.188rem;
$space-244: 15.25rem;
$space-245: 15.313rem;
$space-300: 18.75rem;
$space-342: 21.375rem;
$space-356: 22.25rem;
$space-536: 33.5rem;
$space-1120: 70rem;

// 13. Border radius
$border-radius-4: 0.25rem;
$border-radius-8: 0.5rem;
$border-radius-10: 0.625rem;
$border-radius-12: 0.75rem;
$border-radius-14: 0.875rem;
$border-radius-16: 1rem;
$border-radius-18: 1.125rem;
$border-radius-20: 1.25rem;
$border-radius-24: 1.5rem;
$border-radius-30: 1.875rem;
$border-radius-40: 2.5rem;
$border-radius-48: 3rem;
$border-radius-80: 5rem;

// 13. Responsive Size
// ---------
$xs: 24.3125em; //390px
$sm: 34em; //544px
$md: 48em; //768px
$lg: 62em; //992px
$xl: 80em; //1280px
$xxl: 90em; //1440px

$color-black: #000;
$color-white: #fff;
$color-white-filter: brightness(0) saturate(100%) invert(100%) sepia(100%)
  saturate(0%) hue-rotate(92deg) brightness(107%) contrast(101%);
$color-primary: #f5841f;
$color-dark-primary: #eeb91d;
$color-orange: #f39718;
$color-red: #721c24;
$color-green: #155724;

$color-orange-filter: brightness(0) saturate(100%) invert(62%) sepia(54%)
  saturate(917%) hue-rotate(349deg) brightness(98%) contrast(95%);

$color-scroll: $color-primary;
$color-background-primary: #171717;
$color-background-card: #1a1b23;
$color-text-primary: $color-black;
$color-text-secondary: #c2c2c2;
$color-text-darkgray: #8f8f8f;

$color-input-bg: rgba(35, 35, 37, 0);
$color-input-border: $color-primary;

$font-primary: "Sora", sans-serif;

$animation1: 0.3s;
